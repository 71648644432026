import md5 from "md5";
import { LocalStorage } from "jz-tool-lib";

// 当前系统
export const systemName = process.env["SYSTEM_NAME"] ?? "";
// 服务名称
export const serviceName = {
  bdsaas: "/bdsaas",
  callRest: "/call-rest",
  bdConsole: "/bd-console",
  baseOssRest: "/base-oss-rest",
  baseBizRest: "/base-biz-rest",
  marketVip: "/market-vip-rest", // 服务市场
  baseUpmsRest: "/base-upms-rest",
  agentSystem: "/agent-system-rest", // 代理商
  marketAdmin: "/market-admin-rest", // 运营后台
  baseUserRest: "/base-user-rest", // 运营后台
};

// 数企 和 代理商 环境
export let environmentOrigin = () => {
  const originInfo = {
    bdSaas: "", // vip项目
    reportH5: "", // h5业务报备项目
    bdDaiLi: location.origin,
  };
  const env: string = (process.env.SERVE_ENV || process.env.BUILD_ENV)!;
  if (["demo", "local"].includes(env)) {
    environmentOrigin = () => {
      originInfo.bdSaas = "https://demo.bdsaas.cn";
      originInfo.reportH5 = "https://demo.bdsaas.cn:2880";
      return originInfo;
    };
  } else if (env === "beta") {
    environmentOrigin = () => {
      originInfo.bdSaas = "https://beta.bdsaas.com";
      return originInfo;
    };
  } else {
    environmentOrigin = () => {
      originInfo.bdSaas = "https://vip.bdsaas.com";
      originInfo.reportH5 = "https://qrcode.bdsaas.com:81";
      return originInfo;
    };
  }
  return environmentOrigin();
};
// export const environmentOrigin = () => {
//   const originInfo = {
//     bdSaas: "", // vip项目
//     reportH5: "", // h5业务报备项目
//     bdDaiLi: location.origin,
//   };
//   const buildEnv: any = process.env.BUILD_ENV;
//   if (["demo", "local"].includes(buildEnv)) {
//     originInfo.bdSaas = "https://demo.bdsaas.cn";
//     originInfo.reportH5 = "https://demo.bdsaas.cn:2880";
//   } else if (buildEnv === "beta") {
//     originInfo.bdSaas = "https://beta.bdsaas.com";
//   } else {
//     originInfo.bdSaas = "https://vip.bdsaas.com";
//     originInfo.reportH5 = "https://qrcode.bdsaas.com:81";
//   }
//   return originInfo;
// };
export let environment = () => {
  if (process.env.SERVE_ENV) {
    environment = () => process.env.SERVE_ENV;
  } else if (process.env.BUILD_ENV) {
    environment = () => process.env.BUILD_ENV;
  }
  return environment();
};

// 网络请求加密--防盗刷加密
export function burglarproof(): {
  XWsse: string;
  timestamp: number;
} {
  const nowTimes: number = new Date().getTime();
  return {
    XWsse: md5(
      `token${LocalStorage.getItem("bd_authorization")}timestamp${nowTimes}`
    ),
    timestamp: nowTimes,
  };
}

/*
 * isQsParams: 是否将参数格式化a=1&b=2&c=3形式; yes：可以; no：不可以；
 * */
export const contentType = function (isQsParams: "yes" | "no" = "yes") {
  return {
    headers: {
      qsParams: isQsParams,
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  };
};

// 文件扩展名列表
export function fileExtensionList(type?: "picture" | "text"): string[] {
  const pictureFormatList = ["jpg", "png", "jpeg", "JPG", "PNG", "JPEG"];
  const textFormatList = ["pdf", "doc", "docx", "xlsx", "xls", "txt"];
  if (type === "picture") {
    return pictureFormatList;
  } else if (type === "text") {
    return textFormatList;
  } else {
    return [...pictureFormatList, ...textFormatList];
  }
}

// 正则匹配文件拓展名
export function regExpExtensionName(params: string) {
  const list = fileExtensionList();
  const name = list.find((item) => {
    const reg = new RegExp(`\\.${item}$`);
    return reg.test(params);
  });
  return name ?? "";
}

// 处理202112格式的时间，返回2021-12
export const handlerComboDate = function (data: string) {
  const comboDate = data.toString();
  const year = comboDate.slice(0, 4);
  const month = comboDate.slice(4, 6);
  if (comboDate.length === 6) {
    return `${year}-${month}`;
  } else {
    return "";
  }
};

// 处理图片异常
export function handleImgError(event: any) {
  const img = event.target;
  img.src = require("@/assets/images/default-picture.png");
  img.onerror = null;
}

// 针对多个async/await抛出异常
export async function multipleAwait(func: () => Promise<unknown>) {
  try {
    const result = await func;
    return {
      state: true,
      data: result,
    };
  } catch (err: any) {
    if (err.data) {
      return {
        state: false,
        data: err.data,
      };
    } else {
      return {
        state: false,
        data: undefined,
      };
    }
  }
}

// 根据链接获取文件流
export function getFileFromUrl(
  url: string,
  fileName: string,
  fileType: string
) {
  return new Promise((resolve, reject) => {
    let blob: any = null;
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.setRequestHeader("Accept", fileType);
    xhr.responseType = "blob";
    // 加载时处理
    xhr.onload = () => {
      // 获取返回结果
      blob = xhr.response;
      const file = new File([blob], fileName, { type: fileType });
      // 返回结果
      resolve({
        file,
        blob,
      });
    };
    xhr.onerror = (e) => {
      reject(e);
    };
    // 发送
    xhr.send();
  });
}

// 行业列表数据处理
export function getIndustryTreeMap(data: any) {
  const obj = {};
  const res = [];
  const len = data.length;
  for (let i = 0; i < len; i++) {
    obj[data[i]["code"]] = data[i];
  }
  for (let j = 0; j < len; j++) {
    const list = data[j];
    const parentList = obj[list["parentCode"]];
    if (parentList) {
      if (!parentList["children"]) {
        parentList["children"] = [];
      }
      parentList["children"].push(list);
    } else {
      res.push(list as never);
    }
  }
  return res;
}

// 行业根据code向上递归查找所有父级数据
export function getIndustryLabel(data2: any, code: any, level: any) {
  const labelList: any[] = [];
  const findLabel = (data: any, code: any, level: any) => {
    for (let i = 0; i < data.children.length; i++) {
      const item: any = data.children[i];
      if (item.id == code) {
        labelList.unshift({ level, label: item.name, value: item.id });
        findLabel(data2, data.id, 0);
        break;
      } else if (item.children && item.children.length > 0) {
        findLabel(item, code, level + 1);
      }
    }
  };
  findLabel(data2, code, level);
  return labelList;
}

/**
 * isInit: 是否需要初始值
 * arg: 剩余参数
 * */
export const concatList = function (init, ...arg) {
  return arg.reduce(
    (list, item) => {
      return list.concat(item);
    },
    typeof init === "boolean" ? [{ label: "全部", value: "" }] : init
  );
};

// 获取时间 2008-08-08 00:00:00
export function dateStartOf<T>(value?: T): string {
  if (!value) {
    return dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss");
  } else {
    return dayjs(value).startOf("day").format("YYYY-MM-DD HH:mm:ss");
  }
}

// 获取时间 2008-08-08 23:59:59
export function dateEndOf<T>(value?: T): string {
  if (!value) {
    return dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss");
  } else {
    return dayjs(value).endOf("day").format("YYYY-MM-DD HH:mm:ss");
  }
}
