import secondaryMenu from "@router/menu-router/interface-doc/secondary-menu";

export default [
  {
    path: "/interfaceDoc",
    name: "interface-doc",
    redirect: "/interfaceDoc/general/requestAuthentication",
    component: () => import("@views/interface-doc/index.vue"),
    meta: {
      role: "2",
      filterSubMenu: true,
      pageName: "接口文档",
      iconName: "icon-doc-menu",
    },
    children: secondaryMenu,
  },
];
