export default [
  {
    path: "/businessManagement/gatheringGuests/priceSetting",
    name: "businessManagement.gatheringGuests.priceSetting",
    component: () =>
      import(
        "@views/business-management/gathering-guests/price-setting/index.vue"
      ),
    meta: {
      pageName: "公司单价设置",
      moduleTitle: "设置中心",
    },
  },
];
