export default [
  {
    path: "/interfaceDoc/general/requestAuthentication",
    name: "interfaceDoc.general.requestAuthentication",
    component: () =>
      import("@views/interface-doc/general/requestAuthentication.vue"),
    meta: {
      pageName: "请求鉴权",
      moduleTitle: "通用",
    },
  },
  {
    path: "/interfaceDoc/customerManagement/addCustomerInterface",
    name: "interfaceDoc.customerManagement.addCustomerInterface",
    component: () =>
      import(
        "@views/interface-doc/customer-management/addCustomerInterface.vue"
      ),
    meta: {
      pageName: "新增客户接口",
      moduleTitle: "客户管理",
    },
  },
  {
    path: "/interfaceDoc/callCenter/businessReportingInterface",
    name: "interfaceDoc.callCenter.businessReportingInterface",
    component: () =>
      import("@views/interface-doc/call-center/businessReportingInterface.vue"),
    meta: {
      pageName: "业务报备接口",
      moduleTitle: "呼叫中心",
    },
  },
  {
    path: "/interfaceDoc/callCenter/numberPlusWhiteInterface",
    name: "interfaceDoc.callCenter.numberPlusWhiteInterface",
    component: () =>
      import("@views/interface-doc/call-center/numberPlusWhiteInterface.vue"),
    meta: {
      pageName: "号码加白接口",
      moduleTitle: "呼叫中心",
    },
  },
  {
    path: "/interfaceDoc/callCenter/callInterface",
    name: "interfaceDoc.callCenter.callInterface",
    component: () =>
      import("@views/interface-doc/call-center/callInterface.vue"),
    meta: {
      pageName: "呼叫接口",
      moduleTitle: "呼叫中心",
    },
  },
];
