import fundManagement from "@router/menu-router/financial-center/fund-management";
import billManagement from "@router/menu-router/financial-center/bill-management";
import invoiceManagement from "@router/menu-router/financial-center/invoice-management";
export default [
  {
    path: "/financialCenter",
    name: "financialCenter",
    redirect: "/financialCenter/fundManagement",
    component: () => import("@views/financial-center/index.vue"),
    meta: {
      role: "2",
      pageName: "财务中心",
      iconName: "icon-financial-statistics-menu",
    },
    children: [
      {
        path: "/financialCenter/fundManagement",
        name: "financialCenter.fundManagement",
        redirect: "/financialCenter/fundManagement/incomeExpenditure",
        component: () =>
          import("@views/financial-center/fund-management/index.vue"),
        meta: {
          pageName: "资金管理",
        },
        children: fundManagement,
      },
      {
        path: "/financialCenter/billManagement",
        name: "financialCenter.billManagement",
        redirect: "/financialCenter/billManagement/dailyStatement",
        component: () =>
          import("@views/financial-center/bill-management/index.vue"),
        meta: {
          pageName: "账单管理",
        },
        children: billManagement,
      },
      {
        path: "/financialCenter/invoiceManagement",
        name: "financialCenter.invoiceManagement",
        redirect: "/financialCenter/invoiceManagement/incomeInvoice",
        component: () =>
          import("@views/financial-center/invoice-management/index.vue"),
        meta: {
          pageName: "发票管理",
        },
        children: invoiceManagement,
      },
    ],
  },
];
