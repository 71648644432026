export default [
  {
    path: "/financialCenter/invoiceManagement/incomeInvoice",
    name: "financialCenter.invoiceManagement.incomeInvoice",
    component: () =>
      import(
        "@views/financial-center/invoice-management/income-invoice/index.vue"
      ),
    meta: {
      pageName: "发票管理",
      moduleTitle: "发票管理",
    },
  },
  /*{
    path: "/financialCenter/invoiceManagement/consumptionInvoice",
    name: "financialCenter.invoiceManagement.consumptionInvoice",
    component: () =>
      import(
        "@views/financial-center/invoice-management/consumption-invoice/index.vue"
      ),
    meta: {
      pageName: "销项发票",
      moduleTitle: "发票管理",
    },
  },*/
];
