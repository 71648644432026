export default [
  {
    path: "/financialCenter/fundManagement/incomeExpenditure",
    name: "financialCenter.fundManagement.incomeExpenditure",
    component: () =>
      import(
        "@views/financial-center/fund-management/income-expenditure/index.vue"
      ),
    meta: {
      pageName: "收支明细",
      moduleTitle: "资金管理",
    },
  },
  {
    path: "/financialCenter/fundManagement/recharge",
    name: "financialCenter.fundManagement.recharge",
    component: () =>
      import("@views/financial-center/fund-management/recharge/index.vue"),
    meta: {
      pageName: "充值",
      moduleTitle: "资金管理",
    },
  },
  {
    path: "/financialCenter/fundManagement/withdrawal",
    name: "financialCenter.fundManagement.withdrawal",
    component: () =>
      import("@views/financial-center/fund-management/withdrawal/index.vue"),
    meta: {
      pageName: "提现",
      moduleTitle: "资金管理",
    },
  },
  {
    path: "/financialCenter/fundManagement/fundAllocation",
    name: "financialCenter.fundManagement.fundAllocation",
    component: () =>
      import(
        "@views/financial-center/fund-management/fund-allocation/index.vue"
      ),
    meta: {
      pageName: "资金调拨（现金券）",
      moduleTitle: "资金管理",
    },
  },
];
