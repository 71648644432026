export default [
  {
    path: "/financialCenter/billManagement/dailyStatement",
    name: "financialCenter.billManagement.dailyStatement",
    component: () =>
      import(
        "@views/financial-center/bill-management/daily-statement/index.vue"
      ),
    meta: {
      pageName: "流水账单",
      moduleTitle: "账单管理",
    },
  },
  {
    path: "/financialCenter/billManagement/customerLedger",
    name: "financialCenter.billManagement.customerLedger",
    component: () =>
      import(
        "@views/financial-center/bill-management/customer-ledger/index.vue"
      ),
    meta: {
      pageName: "客户明细账",
      moduleTitle: "账单管理",
    },
  },
];
