import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ElementPlus from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn";
import "element-plus/dist/index.css";
import "@/assets/css/common.scss";
import "@/assets/css/font.scss";
import "@/assets/checkUpdate";
import { LocalStorage, permission } from "jz-tool-lib";

const TabList = defineAsyncComponent(
  () => import("@/components/common/tab-list.vue")
);
const TableEmpty = defineAsyncComponent(
  () => import("@/components/common/table-empty.vue")
);
const CustomDrawer = defineAsyncComponent(
  () => import("@components/common/custom-drawer.vue")
);
const CustomDialog = defineAsyncComponent(
  () => import("@components/common/custom-dialog.vue")
);
const CustomUpload = defineAsyncComponent(
  () => import("@components/common/custom-upload.vue")
);
const PagePagination = defineAsyncComponent(
  () => import("@components/common/page-pagination.vue")
);
const CustomPreviewImage = defineAsyncComponent(
  () => import("@components/common/custom-preview-image.vue")
);
const app = createApp(App);
app.use(store);
app.use(router);
/*
 *  全局引入公共组件
 *  TabList：tab 切换组件
 *  TableEmpty：表格无数据时组件
 *  CustomUpload：上传组件
 *  PagePagination：分页组件
 * */
app.component("TabList", TabList);
app.component("TableEmpty", TableEmpty);
app.component("CustomDrawer", CustomDrawer);
app.component("CustomDialog", CustomDialog);
app.component("CustomUpload", CustomUpload);
app.component("PagePagination", PagePagination);
app.component("PagePagination", PagePagination);
app.component("CustomPreviewImage", CustomPreviewImage);
/*
 * v-permission=[x,xx,xxx] 按钮权限
 * */

app.directive("permission", (el, binding) => {
  permission(el, binding, () => LocalStorage.getItem("bd_roleList"));
});
app.use(ElementPlus, { locale: zhCn, size: "large" });
app.mount("#app");
