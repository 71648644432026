import ospreyCloudCall from "@router/menu-router/business-management/osprey-cloud-call";
import gatheringGuests from "@router/menu-router/business-management/gathering-guests";
export default [
  {
    path: "/businessManagement",
    name: "businessManagement",
    redirect: "/businessManagement/ospreyCloudCall",
    component: () => import("@views/business-management/index.vue"),
    meta: {
      role: "2",
      pageName: "业务管理",
      iconName: "icon-customer-menu",
    },
    children: [
      {
        path: "/businessManagement/ospreyCloudCall",
        name: "businessManagement.ospreyCloudCall",
        redirect: "/businessManagement/ospreyCloudCall/report/list",
        component: () =>
          import("@views/business-management/osprey-cloud-call/index.vue"),
        meta: {
          pageName: "鱼鹰云呼叫",
        },
        children: ospreyCloudCall,
      },
      {
        path: "/businessManagement/gatheringGuests",
        name: "businessManagement.gatheringGuests",
        redirect: "/businessManagement/gatheringGuests/priceSetting",
        component: () =>
          import("@views/business-management/gathering-guests/index.vue"),
        meta: {
          pageName: "21秒云集客",
        },
        children: gatheringGuests,
      },
    ],
  },
];
