export default [
  /**
   *  moduleTitle-业务管理
   */
  // 业务报备
  {
    path: "/businessManagement/ospreyCloudCall/report/list",
    name: "businessManagement.ospreyCloudCall.report.list",
    component: () =>
      import("@views/business-management/osprey-cloud-call/report/list.vue"),
    meta: {
      pageName: "业务报备",
      moduleTitle: "业务管理",
    },
  },
  {
    path: "/businessManagement/ospreyCloudCall/report/detail",
    name: "businessManagement.ospreyCloudCall.report.detail",
    component: () =>
      import("@views/business-management/osprey-cloud-call/report/detail.vue"),
    meta: {
      pageName: "报备详情",
    },
  },
  // 话术报备
  {
    path: "/businessManagement/ospreyCloudCall/speechLibrary",
    name: "businessManagement.ospreyCloudCall.speechLibrary.list",
    component: () =>
      import(
        "@views/business-management/osprey-cloud-call/speech-library/index.vue"
      ),
    meta: {
      pageName: "话术报备",
      moduleTitle: "业务管理",
    },
  },
  // 号码订购
  {
    path: "/businessManagement/ospreyCloudCall/number-ordering/list",
    name: "businessManagement.ospreyCloudCall.numberOrdering.list",
    component: () =>
      import(
        "@views/business-management/osprey-cloud-call/number-ordering/list.vue"
      ),
    meta: {
      pageName: "号码订购",
      moduleTitle: "业务管理",
    },
  },
  {
    path: "/businessManagement/ospreyCloudCall/number-ordering/detail",
    name: "businessManagement.ospreyCloudCall.numberOrdering.detail",
    component: () =>
      import(
        "@views/business-management/osprey-cloud-call/number-ordering/detail.vue"
      ),
    meta: {
      pageName: "详情",
    },
  },
  // 号码加白，暂不做
  // {
  //   path: "/businessManagement/ospreyCloudCall/numberWhite/list",
  //   name: "businessManagement.ospreyCloudCall.numberWhite.list",
  //   component: () =>
  //     import(
  //       "@views/business-management/osprey-cloud-call/number-white/list.vue"
  //     ),
  //   meta: {
  //     pageName: "主叫加白",
  //     moduleTitle: "业务管理",
  //   },
  // },
  // 业务管理
  {
    path: "/businessManagement/ospreyCloudCall/business/list",
    name: "businessManagement.ospreyCloudCall.business.list",
    component: () =>
      import("@views/business-management/osprey-cloud-call/business/list.vue"),
    meta: {
      pageName: "子客户管理",
      moduleTitle: "业务管理",
    },
  },
  {
    path: "/businessManagement/ospreyCloudCall/business/:type/detail",
    name: "businessManagement.ospreyCloudCall.business.detail",
    component: () =>
      import(
        "@views/business-management/osprey-cloud-call/business/detail.vue"
      ),
    props: true,
    meta: {
      pageName: "详情",
      hideMenu: true,
    },
  },
  // 资源管理
  {
    path: "/businessManagement/ospreyCloudCall/resource/list",
    name: "businessManagement.ospreyCloudCall.resource.list",
    component: () =>
      import("@views/business-management/osprey-cloud-call/resource/list.vue"),
    meta: {
      pageName: "资源管理",
      moduleTitle: "业务管理",
    },
  },
  /**
   *  moduleTitle-呼叫统计
   */
  {
    path: "/businessManagement/ospreyCloudCall/callStatistics",
    name: "businessManagement.ospreyCloudCall.callStatistics",
    component: () =>
      import(
        "@views/business-management/osprey-cloud-call/call-statistics/index.vue"
      ),
    meta: {
      pageName: "通话统计",
      moduleTitle: "呼叫统计",
    },
  },
  /**
   *  moduleTitle-通话质检
   *  暂不做
   */
  // {
  //   path: "/businessManagement/ospreyCloudCall/callQualityTesting",
  //   name: "businessManagement.ospreyCloudCall.callQualityTesting",
  //   component: () =>
  //     import(
  //       "@views/business-management/osprey-cloud-call/call-quality-testing/index.vue"
  //     ),
  //   meta: {
  //     pageName: "质检结果",
  //     moduleTitle: "通话质检",
  //   },
  // },
  {
    path: "/businessManagement/ospreyCloudCall/callQualityTesting/detail",
    name: "businessManagement.ospreyCloudCall.callQualityTesting.detail",
    component: () =>
      import(
        "@views/business-management/osprey-cloud-call/call-quality-testing/details.vue"
      ),
    props: true,
    meta: {
      pageName: "详情",
      hideMenu: true,
    },
  },
  /**
   *  moduleTitle-设置中心
   */
  {
    path: "/businessManagement/ospreyCloudCall/priceSetting",
    name: "businessManagement.ospreyCloudCall.priceSetting",
    component: () =>
      import(
        "@views/business-management/osprey-cloud-call/price-setting/index.vue"
      ),
    meta: {
      pageName: "公司单价设置",
      moduleTitle: "设置中心",
    },
  },
  {
    path: "/businessManagement/ospreyCloudCall/customerPackage",
    name: "businessManagement.ospreyCloudCall.customerPackage",
    component: () =>
      import(
        "@views/business-management/osprey-cloud-call/customer-package/index.vue"
      ),
    meta: {
      pageName: "子客户套餐设置",
      moduleTitle: "设置中心",
    },
  },
];
