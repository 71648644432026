import { createStore } from "vuex";
import { systemName } from "@assets/common";

// 公共模块
const commonData = {
  state: () => ({
    // // 用户信息
    // userInfo: LocalStorage.getItem("bd_userInfo"),
    // // 用户token
    // authorization: LocalStorage.getItem("bd_authorization"),
    // // 代理商公司id
    // virCompanyId: LocalStorage.getItem("bd_virCompanyId"),
    // // 代理商id
    // agentId: LocalStorage.getItem("bd_agentId"),
    // // 代理商认证状态
    // checkStatus: LocalStorage.getItem("bd_checkStatus"),
    // // 是否为接口代理商
    // existCallInterface: LocalStorage.getItem("bd_existCallInterface"),
  }),
  mutations: {},
  getters: {},
  actions: {},
};
// 个人中心模块
const personalCenter = {
  state: () => ({
    bankInfo: "", // 添加和修改银行卡信息
  }),
  mutations: {},
  getters: {},
  actions: {},
};
export default createStore({
  state: {
    copyright: ["ec-line"].includes(systemName)
      ? ""
      : `Copyright ©2013-${new Date().getFullYear()} 八度云计算（安徽）有限公司版权所有`,
    certificationStatus: -1, // 认证状态
    checkAdvice: "", // 驳回意见
  },
  mutations: {},
  actions: {},
  modules: {
    commonData,
    personalCenter,
  },
});
