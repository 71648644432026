import home from "./home";
import certificationService from "./certification-service";
import customerManagement from "./customer-management";
import orderManagement from "./order-management";
import businessManagement from "./business-management";
import marketService from "./market-service";
import financialCenter from "./financial-center";
// import financialStatistics from "./financial-statistics";
import personalCenter from "./personal-center";
import payment from "./payment";
import interfaceDoc from "./interface-doc";

export default [
  ...home,
  ...certificationService,
  ...customerManagement,
  ...orderManagement,
  ...businessManagement,
  // ...financialStatistics,
  ...financialCenter,
  ...marketService,
  ...personalCenter,
  ...payment,
  ...interfaceDoc,
];
